import React from 'react';
import '../styles/About.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutSection from './AboutSection';
import PageTitle from './PageTitle';
import bg_title from '../assets/images/bg-title.webp';


const About = () => {

  

  const aboutPageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' }, 
    { text: 'PROJECTS', to: '/projects' },
    { text: 'CONTACT', to: '/contact' }
  ];

  return (
    <>
      <PageTitle title="About Us | StudioPXL - Architecture | Interiors | Masterplanning" />
    
      <section className='about_sec' data-aos="fade-in">
        <Header links={aboutPageLinks}/>
        <div className='bg_title_container'>
          <img src={bg_title} alt='title background'/>
        </div>
        <div><AboutSection/></div>
        
        <Footer/> 
     </section>
          
    </>
  );
};

export default About;