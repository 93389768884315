import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/About.css';
import YassirMuhammadImage from '../assets/images/Yassir_Muhammad.webp';
import KaramaImage from '../assets/images/Abdallah_Karama.webp';
import people_img from '../assets/images/people.webp';

const AboutSection = () => {


  useEffect(() => {
    AOS.init({
      duration: 1200, // animation duration in ms
    });
  }, []);

  return (
    <>
    
      <h1 className='h_about' data-aos="fade-right" data-aos-delay="800">ABOUT US</h1>

      <div className='about_container'>
        <img data-aos="fade-right" data-aos-delay="800" src={people_img} alt="about us img"/>
        <div data-aos="fade-left" data-aos-delay="800" className='p_about'>
          <p>StudioPXL</p>
          <p>Creative, Elegant, Timeless</p>
          <p>
          At StudioPXL, we are a dynamic and innovative architectural firm known for our 
          versatile approach across diverse realms. With our dedicated team of professionals, 
          we excel in every facet of architecture with creativity, precision, and a commitment 
          to excellence. <br></br><br></br>Collaboration is central to our ethos—we work closely with clients, 
          stakeholders, and industry partners to leverage collective expertise. 
          Our open communication fosters an environment of innovation, ensuring 
          the successful realization of our clients' visions.

          </p>
        </div>
        

      </div>

      
      <h2 className='h_team'>OUR TEAM</h2>
      <div className='team'>
        <div className='person' data-aos="fade-up">
          <img src={KaramaImage } alt="Abdallah Muhammad" width='100%'/>
          <h3>Abdallah Muhammad</h3>
          <p className='name_title'>Managing Partner | Lead Consultant</p>
          
        </div>

        <div className='person' data-aos="fade-up">
          <img src={YassirMuhammadImage } alt="Yassir Muhammad" width='100%'/>
          <h3>Yassir Muhammad</h3>
          <p className='name_title'>Managing Partner | Lead Consultant</p>
          

        </div>

      </div>



     
    </>
  );
};

export default AboutSection;