import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Projects from './Projects';
import ContactSection from './ContactSection';
import PageTitle from './PageTitle';
import '../styles/Home.css'; 
import { BsArrowUpRightCircleFill } from "react-icons/bs";

import interior_img from '../assets/images/staircase.webp';
import architecture_img from '../assets/images/planning.webp';
import master_img from '../assets/images/skyscraper.webp';
import interior_container_img from '../assets/images/interior_container_img.webp';
import architecture_container_img from '../assets/images/architecture_container_img.webp';
import master_planning_img from '../assets/images/master_planning_img.webp';


import bg_img from '../assets/images/home_background/bg_img.webp';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // animation duration in ms
    });
  }, []);


  // Define site title and links specific to the Home page
  const homePageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/projects' },
    { text: 'CONTACT', to: '/contact' }
  ];

  return (
    <div className='home_sec' data-aos="fade-in">
      <PageTitle title="Home | StudioPXL - Architecture | Interiors | Masterplanning" />

      {/* Preload images */}
      <img src={bg_img} style={{display: 'none'}} alt='preload'/>

      <Header links={homePageLinks} />

      <div className="background-image-container" >
        <div className='background-image'></div>
        <div className="text-block" data-aos="fade-right" data-aos-delay="800">
          <h1>BUILDING TOMORROW’S <span>ARCHITECTURAL MARVELS</span> </h1>
          <p>EXPERT ARCHITECURE, INTERIOR DESIGN, MASTERPLANNING AND DESIGN - BUILD <span>SOLUTIONS</span></p>
          <div className='project_link_container'>
            <a className='project_link' href="/all_projects">
              Explore Our Work <BsArrowUpRightCircleFill className='project_arrow' />
            </a>
          </div>
        </div>
      </div>

      <div className='service_txt' data-aos="fade-right" data-aos-delay="800">Our Services</div>

      <div className='services_container'>
        <div className='service' data-aos="fade-up" data-aos-delay="500">
          <img src={interior_container_img} alt='interior design' className='service_img'/>         
          <div>
            <img src={interior_img} alt='interior icon' className='services_icons'/>
          </div>
          <h3>INTERIOR DESIGN</h3>
          <p>Transform your surroundings with our expert interior design services, harmoniously blending style and functionality to create a personalized haven of meticulous detail.</p>
        </div>
        <div className='service' data-aos="fade-up" data-aos-delay="500">
          <img src={architecture_container_img} alt='architecture' className='service_img'/>         
          <div>
            <img src={architecture_img} alt='architecture icon' className='services_icons'/>
          </div>
          <h3>ARCHITECTURE</h3>
          <p>Elevate your spaces with StudioPXL's innovative architectural designs, seamlessly blending aesthetics and functionality to craft enduring, inspiring structures.</p>
        </div>
        <div className='service' data-aos="fade-up" data-aos-delay="500">
          <img src={master_planning_img} alt='masterplanning' className='service_img'/>         
          <div>
            <img src={master_img} alt='masterplanning icon' className='services_icons'/>
          </div>
          <h3>MASTER PLANNING</h3>
          <p>Navigate the future with StudioPXL's masterplanning expertise, meticulously crafting comprehensive visions that seamlessly integrate and shape environments for lasting impact.</p>
        </div>       
      </div>

      <main>    
        <section>
          <Projects />
        </section>

        <section>
          <ContactSection />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
