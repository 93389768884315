import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/Contact.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactSection from './ContactSection';
import PageTitle from './PageTitle';
import bg_title from '../assets/images/bg-title.webp';




const Contact = () => {

  useEffect(() => {
    AOS.init({
      duration: 1200, // animation duration in ms
    });
  }, []);


  const contactPageLinks = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' }, // You can omit this if you don't want the About link to appear active
    { text: 'PROJECTS', to: '/projects' },
    { text: 'CONTACT', to: '/contact' }
  ];

  return (
    <>
    <PageTitle title="Contact | StudioPXL - Architecture | Interiors | Masterplanning" />

    <section className='contact_sec'  data-aos="fade-in">
        <Header links={contactPageLinks}/>
        <div className='bg_title_container'>
          <img src={bg_title} alt='title background'/>
        </div>
        <h1 className='h_contact' data-aos="fade-right" data-aos-delay="800">CONTACT</h1>
        <ContactSection/>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249.30234954099276!2d36.81144295331338!3d-1.270410822243958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f170039f5d9fd%3A0x6d41f18c436e7663!2sStudioPXL!5e0!3m2!1sen!2ske!4v1719220150793!5m2!1sen!2ske" title="StudioPXL location" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <Footer/>
    </section>
      
      
    </>
  );
};

export default Contact;