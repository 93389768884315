// Footer.js
import React from 'react';
import { MdLocationOn, MdEmail ,MdCall } from "react-icons/md";
import '../styles/Footer.css';


const Footer = () => {


  return (
    <footer>
      <div className='footer_info_container'>
        <ul className='footer_info'>
          <li><h2>Studio<span className='footer_logo'>PXL</span></h2></li>
          <li>
            <p className='footer_about'>
               A studio specializing in architecture, masterplanning 
               and interior design services. <br/><br/>
               Based in Nairobi, Kenya - serving locally and beyond.</p>
          </li>
        </ul>


        <ul className='footer_info'>
          <li><h2>Contact Us</h2></li>
          <li className='footer_contact_li'>
            <MdEmail className='footer_icon'/>
            <div class="footer-contact-info">
              <span>info@studiopxl.co.ke</span><br></br><br></br>
            </div>
          </li>
          
          <li className='footer_contact_li'>
            <MdCall className='footer_icon'/>
            <div class="footer-contact-info">
              <span>+254 704 667311</span><br></br>
              <span>+254 701 923847</span><br></br><br></br>
            </div>
          </li>

          <li className='footer_contact_li'>
            <MdLocationOn className='footer_icon'/>
            <div class="footer-location-info">
              <span>2129 Office Suites,</span><br></br>
              <span>74 Muthithi Rd - Nairobi, Kenya</span>
            </div>
          </li>

        </ul>

        <ul className='footer_info'>
          <li><h2>Office Hours</h2></li>
          <li><b>Monday - Friday</b></li>
          <li>8:00AM - 5:00PM<br></br><br></br></li>
          <li><b>Saturday</b></li>
          <li>8:00AM - 1:00PM</li>
        </ul>

        <ul className='footer_info'>
          <li><h2>Quick Links</h2></li>
          <li><a className='footer_link_projects' href="/projects">Projects</a></li>
          <li><a className='footer_link_projects' href="/contact">Contact</a></li>
          <li><a className='footer_link_projects' href="/about">About</a></li>
        </ul>

        
      </div>

      <p className='footer_txt'>Copyright &copy; 2024 StudioPXL | All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
