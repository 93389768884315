import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/Projects.css';
import Skyview_image from '../assets/images/Skyview_image.webp';
import interior_container_img from '../assets/images/interior_container_img.webp';
import Abyan_towers_img from '../assets/images/Abyan_towers.webp';
import { FaArrowRightLong } from "react-icons/fa6";



const Projects = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // animation duration in ms
    });
  }, []);

  return (
    <section className='projects_sec'>
      
      <h2 className='h_projects' data-aos="fade-right" data-aos-delay="500">Our Projects</h2>
      <div className='projects_container'>

        <a className='projects_sample' href="/all_projects" data-aos="fade-up" data-aos-delay="800">
          <img src={Abyan_towers_img} alt='sample project'/>
          <div className='project_sample_block'>
            <h3>Abyan Towers (Interior)</h3>
            <p>South C, Nairobi</p>
          </div>


        </a>
        <a className='projects_sample' href="/all_projects" data-aos="fade-up" data-aos-delay="800">
          <img src={Skyview_image} alt='sample project' />
          <div className='project_sample_block'>
            <h3>Skyview Apartments (Exterior)</h3>
            <p>Nairobi</p>
          </div>


        </a>

        <a className='projects_sample' href="/all_projects" data-aos="fade-up" data-aos-delay="800">
          <img src={interior_container_img} alt='sample project'/>
          <div className='project_sample_block'>
            <h3>Eshraq (Interior)</h3>
            <p>Nairobi</p>
          </div>


        </a>

        

      </div>

      <div className='explore_text_container'>
        <a className='explore_text' href="/projects">
           All Projects <FaArrowRightLong className='arrow_right' />
        </a>

      </div>
    
    </section>
  );
};

export default Projects;
