import React, { useRef, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from "@emailjs/browser";
import '../styles/Contact.css';
import { MdLocationOn, MdEmail ,MdCall } from "react-icons/md";



const ContactSection = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      AOS.init({
        duration: 500, // animation duration in ms
      });
      AOS.refresh(); //Initialize AOS
    }, 1000);

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);



  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    // Access form fields
    const name = form.current.user_name.value.trim();
    const email = form.current.user_email.value.trim();
    const message = form.current.message.value.trim();
  
    // Validate fields
    if (!name || !email || !message) {
      alert('Please fill in all fields.');
      return;
    }
  
    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    
  
    // If all validations pass, send the email
    emailjs
      .sendForm(
        "service_cl6v8dh",
        "template_r0e7l18",
        form.current,
        "d28tn4dKb-CjBIwGT",
      )
      .then(
        () => {
          console.log('Message Sent');
          // Display message
          alert('Message sent successfully!');
          // Clear form fields
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          // Optionally display an error message
          alert('Failed to send message. Please try again later.');
        },
      );
  };



  return (
    <section className='contactsection'>
      

      <div className='contact_details_container' data-aos="fade-right" data-aos-delay="600">
        <h3>HAVE A PROJECT IN MIND?</h3>
        <h2>Get In Touch</h2>
        <p>The more details you give the better we can be at providing 
        you with the best service possible. If you aren’t sure, then 
        don't hesitate to give our offices a call. We'd love to talk!</p>
      
        <div className='contact_details'>
          <div className='contact_icon'>
            <MdLocationOn />
          </div>
          <div className='contact_info'>
            2129 Office Suites,<br></br>
            74 Muthithi Rd - Nairobi, Kenya
          </div>
        </div>
        <div className='contact_details'>
          <div className='contact_icon'>
            <MdEmail/>
          </div>
          <div className='contact_info'>
            info@studiopxl.co.ke 
          </div>
        </div>

        <div className='contact_details'>
          <div className='contact_icon'>
            <MdCall />
          </div>
          <div className='contact_info'>
            +254 704 667311 <br />
            +254 701 923847
          </div>
        </div>

   
      </div>

      <div className='contact_form' data-aos="fade-left" data-aos-delay="600">
            <form ref={form} onSubmit={sendEmail}>
              <label>Name*</label>
              <input className='contact_input' type="text" name="user_name" />
              <label>Email Address*</label>
              <input className='contact_input' type="email" name="user_email" />
              <label>How Can We Help You*</label>
              <textarea name="message" />
              <input className='send_button' type="submit" value="Send" />
            </form>
      </div>
      
    </section>
  );
};

export default ContactSection;