import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import '../styles/AllProjects.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from '../components/Footer';
import { FaArrowLeftLong } from "react-icons/fa6";
import Header from '../components/Header';
import PageTitle from './PageTitle';
import bg_title from '../assets/images/bg-title.webp';



//Abyan Towers Images (Interior)//
import imgA1 from '../assets/images/A-Abyan_interior/imgA1.webp';
import imgA2 from '../assets/images/A-Abyan_interior/imgA2.webp';
import imgA3 from '../assets/images/A-Abyan_interior/imgA3.webp';
import imgA4 from '../assets/images/A-Abyan_interior/imgA4.webp';
import imgA5 from '../assets/images/A-Abyan_interior/imgA5.webp';
import imgA6 from '../assets/images/A-Abyan_interior/imgA6.webp';
import imgA7 from '../assets/images/A-Abyan_interior/imgA7.webp';
import imgA8 from '../assets/images/A-Abyan_interior/imgA8.webp';
import imgA9 from '../assets/images/A-Abyan_interior/imgA9.webp';
import imgA10 from '../assets/images/A-Abyan_interior/imgA10.webp';
import imgA11 from '../assets/images/A-Abyan_interior/imgA11.webp';

//Abyan Towers Images (Exterior)//
import imgB1 from '../assets/images/B-Abyan_exterior/imgB1.webp';
import imgB2 from '../assets/images/B-Abyan_exterior/imgB2.webp';
import imgB3 from '../assets/images/B-Abyan_exterior/imgB3.webp';
import imgB4 from '../assets/images/B-Abyan_exterior/imgB4.webp';

//Eshraq Images (interior) //
import imgC1 from '../assets/images/C-Eshraq_interior/imgC1.webp';
import imgC2 from '../assets/images/C-Eshraq_interior/imgC2.webp';
import imgC3 from '../assets/images/C-Eshraq_interior/imgC3.webp';
import imgC4 from '../assets/images/C-Eshraq_interior/imgC4.webp';
import imgC5 from '../assets/images/C-Eshraq_interior/imgC5.webp';
import imgC6 from '../assets/images/C-Eshraq_interior/imgC6.webp';
import imgC7 from '../assets/images/C-Eshraq_interior/imgC7.webp';
import imgC8 from '../assets/images/C-Eshraq_interior/imgC8.webp';

//Eshraq Images (exterior) //
import imgD1 from '../assets/images/D-Eshraq_exterior/imgD1.webp';
import imgD2 from '../assets/images/D-Eshraq_exterior/imgD2.webp';
import imgD3 from '../assets/images/D-Eshraq_exterior/imgD3.webp';
import imgD4 from '../assets/images/D-Eshraq_exterior/imgD4.webp';

//Kingdom Tower 2 Images (exterior) //
import imgE1 from '../assets/images/E-Kingdom_tower/imgE1.webp';
import imgE2 from '../assets/images/E-Kingdom_tower/imgE2.webp';
import imgE3 from '../assets/images/E-Kingdom_tower/imgE3.webp';
import imgE4 from '../assets/images/E-Kingdom_tower/imgE4.webp';
import imgE5 from '../assets/images/E-Kingdom_tower/imgE5.webp';

//Next Gym Images (interior) //
import imgF1 from '../assets/images/F-Next_gym_interior/imgF1.webp';
import imgF2 from '../assets/images/F-Next_gym_interior/imgF2.webp';
import imgF3 from '../assets/images/F-Next_gym_interior/imgF3.webp';
import imgF4 from '../assets/images/F-Next_gym_interior/imgF4.webp';
import imgF5 from '../assets/images/F-Next_gym_interior/imgF5.webp';
import imgF6 from '../assets/images/F-Next_gym_interior/imgF6.webp';
import imgF7 from '../assets/images/F-Next_gym_interior/imgF7.webp';
import imgF8 from '../assets/images/F-Next_gym_interior/imgF8.webp';
import imgF9 from '../assets/images/F-Next_gym_interior/imgF9.webp';
import imgF10 from '../assets/images/F-Next_gym_interior/imgF10.webp';
import imgF11 from '../assets/images/F-Next_gym_interior/imgF11.webp';
import imgF12 from '../assets/images/F-Next_gym_interior/imgF12.webp';
import imgF13 from '../assets/images/F-Next_gym_interior/imgF13.webp';
import imgF14 from '../assets/images/F-Next_gym_interior/imgF14.webp';
import imgF15 from '../assets/images/F-Next_gym_interior/imgF15.webp';
import imgF16 from '../assets/images/F-Next_gym_interior/imgF16.webp';

//Skyview Apartments Images (interior) //
import imgG1 from '../assets/images/G-Skyview_interior/imgG1.webp';
import imgG2 from '../assets/images/G-Skyview_interior/imgG2.webp';
import imgG3 from '../assets/images/G-Skyview_interior/imgG3.webp';
import imgG4 from '../assets/images/G-Skyview_interior/imgG4.webp';
import imgG5 from '../assets/images/G-Skyview_interior/imgG5.webp';
import imgG6 from '../assets/images/G-Skyview_interior/imgG6.webp';
import imgG7 from '../assets/images/G-Skyview_interior/imgG7.webp';
import imgG8 from '../assets/images/G-Skyview_interior/imgG8.webp';
import imgG9 from '../assets/images/G-Skyview_interior/imgG9.webp';
import imgG10 from '../assets/images/G-Skyview_interior/imgG10.webp';

//Skyview Apartments Images (exterior) //
import imgH1 from '../assets/images/H-Skyview_exterior/imgH1.webp';
import imgH2 from '../assets/images/H-Skyview_exterior/imgH2.webp';
import imgH3 from '../assets/images/H-Skyview_exterior/imgH3.webp';
import imgH4 from '../assets/images/H-Skyview_exterior/imgH4.webp';

//The Curve Images (interior) //
import imgI1 from '../assets/images/I-The_curve_kileleshwa_interior/imgI1.webp';
import imgI2 from '../assets/images/I-The_curve_kileleshwa_interior/imgI2.webp';
import imgI3 from '../assets/images/I-The_curve_kileleshwa_interior/imgI3.webp';
import imgI4 from '../assets/images/I-The_curve_kileleshwa_interior/imgI4.webp';

//The Curve Images (exterior) //
import imgJ1 from '../assets/images/J-The_curve_kileleshwa_exterior/imgJ1.webp';
import imgJ2 from '../assets/images/J-The_curve_kileleshwa_exterior/imgJ2.webp';
import imgJ3 from '../assets/images/J-The_curve_kileleshwa_exterior/imgJ3.webp';
import imgJ4 from '../assets/images/J-The_curve_kileleshwa_exterior/imgJ4.webp';

const projectsData = [
  {
    title: ["Abyan Towers (Interior)", "South C, Nairobi"],
    images: [imgA1, imgA2, imgA3, imgA4, imgA5, imgA6, imgA7, imgA8, imgA9, imgA10, imgA11],
    category: "INTERIORS",
  },
  {
    title: ["Abyan Towers (Exterior)", "South C, Nairobi"],
    images: [imgB1, imgB2, imgB3, imgB4],
    category: "ARCHITECTURE",
  },
  {
    title: ["Eshraq (Interior)", "Nairobi"],
    images: [imgC1, imgC2, imgC3, imgC4, imgC5, imgC6, imgC7, imgC8],
    category: "INTERIORS",
  },
  {
    title: ["Eshraq (Exterior)", " Nairobi"],
    images: [imgD1, imgD2, imgD3, imgD4],
    category: "ARCHITECTURE",
  },
  {
    title: ["Kingdom Tower 2 (Exterior)", " Nairobi"],
    images: [imgE1, imgE2, imgE3, imgE4, imgE5],
    category: "ARCHITECTURE",
  },
  {
    title: ["Next Gym (Interior)", " Nairobi"],
    images: [imgF1, imgF2, imgF3, imgF4, imgF5, imgF6, imgF7, imgF8, imgF9, imgF10, imgF11, imgF12, imgF13, imgF14, imgF15, imgF16],
    category: "INTERIORS",
  },
  {
    title: ["Skyview Apartments (Interior)", " Nairobi"],
    images: [imgG1, imgG2, imgG3, imgG4, imgG5, imgG6, imgG7, imgG8, imgG9, imgG10],
    category: "INTERIORS",
  },
  {
    title: ["Skyview Apartments (Exterior)", " Nairobi"],
    images: [imgH1, imgH2, imgH3, imgH4],
    category: "ARCHITECTURE",
  },
  {
    title: ["The Curve (Interior)", "Kileleshwa, Nairobi"],
    images: [imgI1, imgI2, imgI3, imgI4],
    category: "INTERIORS",
  },
  {
    title: ["The Curve (Exterior)", "Kileleshwa, Nairobi"],
    images: [imgJ1, imgJ2, imgJ3, imgJ4],
    category: "ARCHITECTURE",
  },

  // Add more project objects here as needed
];

const AllProjects = () => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("ALL");

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return <div className={`${className} custom-arrow custom-prev-arrow`} onClick={onClick}></div>;
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return <div className={`${className} custom-arrow custom-next-arrow`} onClick={onClick}></div>;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  const handleClick = (index) => {
    // Find the index of the selected project within the filteredProjects array
    const projectIndex = projectsData.findIndex(project => project === filteredProjects[index]);
    // Set the index of the selected project
    setSelectedProjectIndex(projectIndex);
    // Show the carousel
    setShowCarousel(true);
  };

  const handleClose = () => {
    setShowCarousel(false);
  };

  // Filter projects based on selected filter
  const filteredProjects = projectsData.filter(project => selectedFilter === "ALL" || project.category === selectedFilter);

  const links = [
    { text: 'HOME', to: '/' },
    { text: 'ABOUT', to: '/about' },
    { text: 'PROJECTS', to: '/projects' },
    { text: 'CONTACT', to: '/contact' }
  ];

  useEffect(() => {
    AOS.init({
      duration: 1200, // animation duration in ms
    });
  }, []);

  return (
    <>
      <PageTitle title="Projects | StudioPXL - Architecture | Interiors | Masterplanning" />
      <section className='allprojects_sec' data-aos="fade-in">
      <Header links={links} />
      <div className='bg_title_container'>
          <img src={bg_title} alt='title background'/>
      </div>
      <h1 className='h_all_projects' data-aos="fade-right" data-aos-delay="800">PROJECTS</h1>

      {!showCarousel && (
        <>

          <div className='project_divide'>
            <button className='filter_1' onClick={() => setSelectedFilter("ARCHITECTURE")}>ARCHITECTURE</button>
            <span>|</span>
            <button className='filter_2' onClick={() => setSelectedFilter("INTERIORS")}>INTERIORS</button>
            <span>|</span>
            <button className='filter_3' onClick={() => setSelectedFilter("ALL")}>ALL</button>
          </div>

          <div className='allprojects_container' >
            {filteredProjects.map((project, index) => (
              <div key={index} onClick={() => handleClick(index)} className='allprojects_sample' data-aos="zoom-in">
                <img src={project.images[0]} alt='sample project' /> {/* Display only the first image */}
                <div className='all_projects_sample_block'>
                  <h3>{project.title[0]}</h3>
                  <p>{project.title[1]}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='back_text_container'>
            <a href="/" className='back_text'>
              <FaArrowLeftLong className='arrow_left' />Go Back
            </a>
          </div>

          <Footer />
        </>
      )}

      {showCarousel && (
        <div className='carousel-overlay' data-aos="zoom-in">
          <button className="close-text" onClick={handleClose}>CLOSE</button>
          <div className="carousel-container">
            <Slider {...settings} onClick={(e) => e.stopPropagation()}>
              {projectsData[selectedProjectIndex].images.map((image, index) => (
                <div key={index} className="carousel-image-container">
                  <img src={image} alt='project' className="slider-image" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </section>
    </>
    
  );
};

export default AllProjects;
