import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Header.css';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MdClose } from 'react-icons/md';

const Header = ({ links = [] }) => {
  // Function to show the side navigation
  const shownav_side = () => {
    const nav_side = document.querySelector('.nav_side ul');
    nav_side.style.display = 'flex';
  };

  // Function to hide the side navigation
  const hidenav_side = () => {
    const nav_side = document.querySelector('.nav_side ul');
    nav_side.style.display = 'none';
  };

  return (
    <header>
      {/* Navigation for small screens */}
      <nav className="nav_side">
        <ul>
          <button type="button" className="close" onClick={hidenav_side}>
            <MdClose />
          </button>
          {links.map((link, index) => (
            <li key={index}>
              <NavLink
                to={link.to}
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={hidenav_side}
              >
                {link.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      {/* Navigation for larger screens */}
      <nav className="nav">
        <NavLink className="site-title" to="/">
          <span className="logo_text">Studio<span className='highlighted_title'>PXL</span></span>
        </NavLink>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <NavLink
                to={link.to}
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={hidenav_side}
              >
                {link.text}
              </NavLink>
            </li>
          ))}
        </ul>

        <button type="button" className="ham_menu" onClick={shownav_side}>
          <RxHamburgerMenu />
        </button>
      </nav>
    </header>
  );
};

export default Header;
